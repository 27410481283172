import React, { Component } from "react";
const WOW = require("wowjs");

class Financial extends Component {
  componentDidMount() {
    new WOW.WOW({
      animateClass: "animated",
      offset: 20,
      mobile: true,
      live: true,
    }).init();
  }

  render() {
    return (
      <section id="features-consultoring" class="features">
        <div class="container">
          <div class="payment-processing-section-title">
            <h2>Consultoría Financiera</h2>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="single-features-box">
                <i class="fas fa-users"></i>
                <h3>Clientes</h3>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-features-box">
                <div className="uno">
                  <i class="fas fa-truck"></i>
                  <h3>Proveedores</h3>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-features-box">
                <i class="fas fa-clipboard"></i>
                <h3>Inventario</h3>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-features-box">
                <i class="fas fa-landmark"></i>
                <h3>Bancos</h3>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-features-box">
                <i class="fas fa-calendar-alt"></i>
                <h3>Administración</h3>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-features-box">
                <i class="fas fa-cash-register"></i>
                <h3>Ventas</h3>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-features-box">
                <i class="fas fa-hand-holding-usd"></i>
                <h3>Financiamientos de Corto Plazo</h3>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-features-box">
                <i class="fas fa-chart-line"></i>
                <h3>Créditos de Largo Plazo</h3>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-features-box">
                <i class="fas fa-file-invoice-dollar"></i>
                <h3>Renegociación de Deudas</h3>
              </div>
            </div>
          </div>
        </div>
        <section id="contact" class="erp-contact">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="contact-content-box">
                  <h3>¿Necesitas asesoria financiera?</h3>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="contact-connect">
                  <a
                    class="talk-to-sales"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://api.whatsapp.com/send/?phone=%2B56940870001&text=Hola%2C+Necesito+M%C3%A1s+Informaci%C3%B3n&type=phone_number&app_absent=0"
                  >
                    Escribenos a nuestro WhatsApp
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default Financial;
