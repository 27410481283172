import React, { Component } from 'react';
import Navbar from '../components/Layouts/Navbar';
import Footer from '../components/Layouts/Footer';
import Banner from '../components/Home/Banner';
import About from '../components/Home/About';
import Services from '../components/Home/Services';
import Methodology from '../components/Home/methodology';
//import Testimonials from '../components/Home/Testimonials';
//import Client from '../components/Home/Client';
//import Team from '../components/Home/Team';
import Partner from '../components/Home/Partner';
import ModalCookies from '../components/Shared/ModalCookie';
//import Contact from '../components/Home/Contact';
import { StepsProvider } from "react-step-builder";

class Home extends Component {
    render() {
        return (
            <React.Fragment>
                <StepsProvider>
                <ModalCookies/>
                </StepsProvider>               
                <Navbar />
                <Banner />
                <About />
                <Methodology />
                <Services />
                <Partner />
                {/*<Faq />   
                <Testimonials />
                <Client />
                <Team />
                <Contact />*/}
                <Footer />
            </React.Fragment>
           
        );
    }
}

export default Home;
