import React, { Component } from "react";
const WOW = require("wowjs");

class Technology extends Component {
  componentDidMount() {
    new WOW.WOW({
      animateClass: "animated",
      offset: 20,
      mobile: true,
      live: true,
    }).init();
  }

  render() {
    return (
      <section id="features-consultoring" class="features">
        <div class="container">
          <div class="payment-processing-section-title">
            <h2>Consultoría Tecnológica</h2>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="single-features-box">
                <i class="fas fa-envelope"></i>
                <h3>Emails Corporativos</h3>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-features-box">
                <i class="fas fa-desktop"></i>
                <h3>Página Web</h3>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-features-box">
                <i class="fas fa-shopping-cart"></i>
                <h3>E-Commerce</h3>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-features-box">
                <i class="fas fa-cash-register"></i>
                <h3>Ventas</h3>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-features-box">
                <i class="fas fa-receipt"></i>
                <h3>Compras</h3>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-features-box">
                <i class="fas fa-hand-holding-usd"></i>
                <h3>Contabilidad</h3>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-features-box">
                <i class="fas fa-clipboard"></i>
                <h3>Inventario</h3>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-features-box">
                <i class="fas fa-users"></i>
                <h3>Recursos Humanos</h3>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-features-box">
                <i class="fas fa-tasks"></i>
                <h3>Checklist</h3>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-features-box">
                <i class="fas fa-industry"></i>
                <h3>ERP</h3>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-features-box">
                <i class="fas fa-bus-alt"></i>
                <h3>Fleet</h3>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-features-box">
                <i class="far fa-chart-bar"></i>
                <h3>IOT</h3>
              </div>
            </div>
          </div>
        </div>
        <section id="contact" class="erp-contact">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="contact-content-box">
                  <h3>¿Necesitas soluciones tecnológicas innovadoras?</h3>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="contact-connect">
                  <a
                    class="talk-to-sales"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://api.whatsapp.com/send/?phone=%2B56940870001&text=Hola%2C+Necesito+M%C3%A1s+Informaci%C3%B3n&type=phone_number&app_absent=0"
                  >
                    Escribenos a nuestro WhatsApp
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default Technology;
