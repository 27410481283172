import React, { Component } from "react";
const WOW = require("wowjs");

class Company extends Component {
  componentDidMount() {
    new WOW.WOW({
      animateClass: "animated",
      offset: 20,
      mobile: true,
      live: true,
    }).init();
  }

  render() {
    return (
      <section id="features-consultoring" class="features">
        <div class="container">
          .
          <div class="payment-processing-section-title">
            <h2>Consultoría Estratégica</h2>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="single-features-box">
                <i class="fas fa-project-diagram"></i>
                <h3>Procesos</h3>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-features-box">
                <i class="fas fa-sitemap"></i>
                <h3>Modelo de Negocio</h3>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-features-box">
                <i class="fas fa-bullhorn"></i>
                <h3>Nuevos Productos</h3>
              </div>
            </div>
          </div>
        </div>
        <section id="contact" class="erp-contact">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="contact-content-box">
                  <h3>¿Que problemas necesitas resolver?</h3>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="contact-connect">
                  <a
                    class="talk-to-sales"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://api.whatsapp.com/send/?phone=%2B56940870001&text=Hola%2C+Necesito+M%C3%A1s+Informaci%C3%B3n&type=phone_number&app_absent=0"
                  >
                    Escribenos a nuestro WhatsApp
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default Company;
