import React, { Component } from "react";
import { Link } from "react-router-dom";

class NavbarSix extends Component {
  state = {
    collapsed: true,
  };

  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  componentDidMount() {
    this._isMounted = true;
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
    window.scrollTo(0, 0);

    this.menuActiveClass();
  }

  menuActiveClass = () => {
    let mainNavLinks = document.querySelectorAll("nav ul li a");
    window.addEventListener("scroll", () => {
      let fromTop = window.scrollY;
      mainNavLinks.forEach((link) => {
        if (link.hash) {
          let section = document.querySelector(link.hash);

          if (
            section &&
            section.offsetTop <= fromTop &&
            section.offsetTop + section.offsetHeight > fromTop
          ) {
            link.classList.add("active");
          } else {
            link.classList.remove("active");
          }
        }
      });
    });
  };

  render() {
    const { collapsed } = this.state;
    const classOne = collapsed
      ? "collapse navbar-collapse"
      : "navbar-collapse collapse show";
    const classTwo = collapsed
      ? "navbar-toggler navbar-toggler-right collapsed"
      : "navbar-toggler navbar-toggler-right";
    return (
      <React.Fragment>
        <nav
          id="navbar"
          className="navbar navbar-expand-lg navbar-style-one navbar-light bg-light"
        >
          <div className="container">
            <Link to="/" className="navbar-brand">
              <img
                src={require("../../images/white-logo-nav.png")}
                alt="logo"
              />
              <img src={require("../../images/black-logo.png")} alt="logo" />
            </Link>
            <button
              onClick={this.toggleNavbar}
              className={classTwo}
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className={classOne} id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a class="nav-link" href="/">
                    Inicio
                  </a>
                </li>
                <li className="nav-item">
                  <a class="nav-link" href="/#about">
                    Nosotros
                  </a>
                </li>
                <li className="nav-item">
                  <a class="nav-link" href="/#metodologia">
                    Metodología
                  </a>
                </li>
                <li className="nav-item">
                  <a class="nav-link" href="/#consultorias">
                    Consultoría
                  </a>
                </li>
              </ul>
              <ul className="others-option">
                <li>
                  <Link to="/" className="nav-btn btn-primary">
                    Contacto
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

export default NavbarSix;
