import React, { Component } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import "../Home/services.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);

Modal.setAppElement("#root");

class Services extends Component {
  constructor() {
    super();
    this.state = {
      showModalIF: false,
      showModalE: false,
      showModalRH: false,
      showModalT: false,
    };
  }
  handleClick = (evt, item) => {
    evt.preventDefault();
    this.setState({ [item]: true });
  };
  handleCloseModal = (item) => {
    this.setState({ [item]: false });
  };
  render() {
    return (
      <section id="consultorias" className="services-area-two ptb-100">
        <section className="separator-area"></section>
        <div className="container">
          <div className="default-section-title">
            <h2>Consultoría</h2>
            <div className="bar"></div>
          </div>

          {/* <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="services-box" onClick={(evt) => { this.handleClick(evt, 'showModalIF') }} style={{ cursor: 'pointer' }}>
                <img src={require("../../images/car-services1.jpg")} alt="services" />
                <div className="services-content">
                  <h3>Financieras</h3>
                </div>
              </div>
              <Modal isOpen={this.state.showModalIF} contentLabel="Instituciones Financieras" style={customStyles}>
                <Financial />
                <div className="col-md-12" style={{ textAlign: 'center' }}>
                  <button className="btn btn-primary" style={{ position: 'absolute' }} onClick={() => { this.handleCloseModal('showModalIF') }}>Cerrar</button>
                </div>
              </Modal>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="services-box" onClick={(evt) => { this.handleClick(evt, 'showModalE') }} style={{ cursor: 'pointer' }}>
                <img src={require("../../images/car-services2.jpg")} alt="services" />
                <div className="services-content">
                  <h3>Empresariales</h3>
                </div>
              </div>
              <Modal isOpen={this.state.showModalE} contentLabel="Empresas" style={customStyles}>
                <Company />
                <div className="col-md-12" style={{ textAlign: 'center' }}>
                  <button className="btn btn-primary" style={{ position: 'absolute' }} onClick={() => { this.handleCloseModal('showModalE') }}>Cerrar</button>
                </div>
              </Modal>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="services-box" onClick={(evt) => { this.handleClick(evt, 'showModalT') }} style={{ cursor: 'pointer' }}>
                <img src={require("../../images/car-services4.jpg")} alt="services" />
                <div className="services-content">
                  <h3>Tecnologícas</h3>
                </div>
              </div>
              <Modal isOpen={this.state.showModalT} contentLabel="Tecnología" style={customStyles}>
                <Technology />
                <div className="col-md-12" style={{ textAlign: 'center' }}>
                  <button className="btn btn-primary" style={{ position: 'absolute' }} onClick={() => { this.handleCloseModal('showModalT') }}>Cerrar</button>
                </div>
              </Modal>
            </div>
          </div>*/}
        </div>
        <div className="container">
          <div className="row">
          <div className="col-lg-4 col-md-6">
              <div className="services-wrap ftco-animate">
              <div className="single-features">
                <div className="icon bg1">
                  <i className="fas fa-briefcase" aria-hidden="true"></i>
                </div>
                <h3>ESTRATÉGICA</h3>
                <p>
                  Resolvemos problemas en áreas clave de tu negocio.
                </p>
                <div className="text">
                  <Link to="/process" className="btn-custom">MÁS INFORMACIÓN</Link>
                </div>
              </div>
            </div>
              </div>
              {/*<Modal isOpen={this.state.showModalT} contentLabel="Tecnología" style={customStyles}>
                <Technology />
                <div className="col-md-12" style={{ textAlign: 'center' }}>
                  <button className="btn btn-primary" style={{ position: 'absolute' }} onClick={() => { this.handleCloseModal('showModalT') }}>Cerrar</button>
                </div>
              </Modal>*/}

            <div className="col-lg-4 col-md-6">
              <div className="services-wrap ftco-animate">
              <div className="single-features">
                <div className="icon bg1">
                  <i className="fas fa-dollar-sign" aria-hidden="true"></i>
                </div>
                <h3>FINANCIERA</h3>
                <p>
                  Busca ahorros y obtén financiamiento para tu empresa.
                </p>
                <div className="text">
                <Link to="/financial" className="btn-custom">MÁS INFORMACIÓN</Link>
                </div>
              </div>
            </div>
              </div>
              {/*<Modal isOpen={this.state.showModalT} contentLabel="Tecnología" style={customStyles}>
                <Technology />
                <div className="col-md-12" style={{ textAlign: 'center' }}>
                  <button className="btn btn-primary" style={{ position: 'absolute' }} onClick={() => { this.handleCloseModal('showModalT') }}>Cerrar</button>
                </div>
              </Modal>*/}

            <div className="col-lg-4 col-md-6">
              <div className="services-wrap ftco-animate">
              <div className="single-features">
                <div className="icon bg1">
                  <i className="fas fa-laptop" aria-hidden="true"></i>
                </div>
                <h3>TECNOLOGÍCA</h3>
                <p>
                  Gestiona tu empresa con soluciones tecnológicas innovadoras.
                </p>
                <div className="text">
                  <Link to="/technology" className="btn-custom">MÁS INFORMACIÓN</Link>
                </div>
              </div>
            </div>
              </div>
              {/*<Modal isOpen={this.state.showModalT} contentLabel="Tecnología" style={customStyles}>
                <Technology />
                <div className="col-md-12" style={{ textAlign: 'center' }}>
                  <button className="btn btn-primary" style={{ position: 'absolute' }} onClick={() => { this.handleCloseModal('showModalT') }}>Cerrar</button>
                </div>
              </Modal>*/}
            </div>
          </div>
      </section>
    );
  }
}
export default Services;
