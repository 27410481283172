import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel3';

const options = {
    loop: true,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    navText: [
        "<i class='fas fa-angle-left'></i>",
        "<i class='fas fa-angle-right'></i>"
    ],
    responsive: {
        0: {
            items:2,
        },
        768: {
            items:3,
        },
        1024: {
            items: 4,
        },
        1200: {
            items:6,
        }
    }
}

class Partner extends Component {

    state = { display:false};

    componentDidMount(){
        this.setState({ display: true })
    }

    render() {
        return (
            <section className="partner-area">
                <section className='separator-area'>
                </section>
                <div className="container">
                    <div className="row">
                        {this.state.display ? <OwlCarousel
                            className="partner-slides owl-carousel owl-theme"
                            {...options}
                        >
                            <div className="col-lg-12 col-md-12">
                                <div className="partner-item">
                                    <img src={require('../../images/aws.png')} alt="logo" />
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="partner-item">
                                    <img src={require('../../images/neokode.png')} alt="logo" />
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="partner-item">
                                    <img src={require('../../images/odoo.png')} alt="logo" />
                                </div>
                            </div>
                        </OwlCarousel> : ''}
                    </div>
                </div>
            </section>
        );
    }
}

export default Partner;
