import React from 'react'
import { Link } from "react-router-dom";

class NotFound extends React.Component {
    static getInitialProps({ res, err }) {
        const statusCode = res ? res.statusCode : err ? err.statusCode : null;
        return { statusCode };
    }

    render() {
        return (
            <React.Fragment>
                <section className="error-area ptb-100">
                    <div className="container">
                        <div className="error-content">
                            <img src={require("../images/404.png")} alt="error" />
                            <Link to="/" className="btn btn-primary">Ir al inicio</Link>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default NotFound;
