import React, { Component } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);


class Methodology extends Component {
  state = { display: false };

  componentDidMount() {
    this.setState({ display: true });
  }

  render() {
    return (
      <section id="metodologia" className="ftco-section">
        <section className="separator-area"></section>
        <div className="container">
          <div className="row justify-content-center pb-5 mb-3">
            <div className="col-md-7 default-section-title">
              <h2>Metodología</h2>
              <div className="bar"></div>
            </div>
          </div>
          <div className="row d-flex no-gutters">
            <div className="col-md-6 d-flex">
              <div className="img-method d-flex align-self-stretch align-items-center justify-content-center justify-content-md-end mb-4 mb-sm-0">
              </div>
            </div>
            <div className="col-md-6 pl-md-5 py-md-5">
              <div className="services-2 w-100 d-flex">
                <div className="icon bg1 d-flex align-items-center justify-content-center">
                  <span className="fas fa-clipboard"></span>
                </div>
                <div className="text pl-4">
                  <h4>Definición</h4>
                  <p>
                    Establecemos la situación actual del problema y los objetivos que deseamos conseguir.
                  </p>
                </div>
              </div>
              <div className="services-2 w-100 d-flex">
                <div className="icon bg1 d-flex align-items-center justify-content-center">
                  <span className="fas fa-pencil-ruler"></span>
                </div>
                <div className="text pl-4">
                  <h4>Medición</h4>
                  <p>
                    Validamos las métricas e identificamos todas las variables que influyen en los procesos.
                  </p>
                </div>
              </div>
              <div className="services-2 w-100 d-flex">
                <div className="icon bg1 d-flex align-items-center justify-content-center">
                  <span className="fas fa-chart-bar"></span>
                </div>
                <div className="text pl-4">
                  <h4>Análisis</h4>
                  <p>
                    Validamos las causas del problema, los factores críticos y creamos un plan de mejoras.
                  </p>
                </div>
              </div>
              <div className="services-2 w-100 d-flex">
                <div className="icon bg1 d-flex align-items-center justify-content-center">
                  <span className="fas fa-check-double"></span>
                </div>
                <div className="text pl-4">
                  <h4>Mejora</h4>
                  <p>
                    Se diseña, se prueba y se Implementa la solución propuesta en la etapa de análisis.
                  </p>
                </div>
              </div>
              <div className="services-2 w-100 d-flex">
                <div className="icon bg1 d-flex align-items-center justify-content-center">
                  <span className="fas fa-tasks"></span>
                </div>
                <div className="text pl-4">
                  <h4>Control</h4>
                  <p>
                    Mantener las soluciones en el tiempo.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Methodology;
