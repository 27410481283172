import React, { Component } from "react";
import { Link } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";

class Footer extends Component {
  render() {
    return (
      <footer className="footer-area bg-fffcf4">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="single-footer-widget">
                <div className="logo">
                  <Link to="/">
                    <img
                      src={require("../../images/white-logo-nav.png")}
                      alt="logo"
                    />
                  </Link>
                </div>

                <ul className="contact-info">
                  <li>Awesome Consulting Limitada</li>
                  <li>
                    <a href="mailto:">contacto@isawsome.cl</a>
                  </li>
                  <li>
                    <Link to="/">www.isawesome.cl</Link>
                  </li>
                  {/* <li>
                    <a>+56940870001</a>
                  </li> */}
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="single-footer-widget pl-3">
                <h3>Redes sociales</h3>

                <ul className="contact-info">
                  <li>
                    <AnchorLink
                      onClick={this.toggleNavbar}
                      offset={() => 0}
                     
                    >
                      <i className="fab fa-facebook"></i>
                      Facebook
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink
                      onClick={this.toggleNavbar}
                      offset={() => 0}
                      
                    >
                      <i className="fab fa-instagram"></i>
                      Instagram
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink
                      onClick={this.toggleNavbar}
                      offset={() => 0}
                      
                    >
                      <i className="fab fa-twitter"></i>
                      Twitter
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink
                      onClick={this.toggleNavbar}
                      offset={() => 0}
                      
                    >
                      <i className="fab fa-linkedin"></i>
                      Linkedin
                    </AnchorLink>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="single-footer-widget pl-3">
                <h3>Enlaces r&aacute;pidos</h3>

                <ul className="contact-info">
                <li>
                    <AnchorLink
                      onClick={this.toggleNavbar}
                      offset={() => 0}
                      href="#home"
                    >
                      Inicio
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink
                      onClick={this.toggleNavbar}
                      offset={() => 0}
                      href="#about"
                    >
                      Nosotros
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink
                      onClick={this.toggleNavbar}
                      offset={() => 0}
                      href="#metodologia"
                    >
                      Metodología
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink
                      onClick={this.toggleNavbar}
                      offset={() => 0}
                      href="#consultorias"
                    >
                      Consultoría
                    </AnchorLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6">
                <p>
                  <i className="far fa-copyright"></i> 2023{" "}
                  <a href="/">Awesome Consulting Limitada</a>. Todos los
                  derechos reservados
                </p>
              </div>
              <div className="col-lg-6 col-md-6">
                <p style={{ textAlign: "center" }}>
                  Desarrollado por{" "}
                  <a
                    href="https://www.neokode.cl"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Neokode
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="map2">
          <img src={require("../../images/map2.png")} alt="map" />
        </div>
      </footer>
    );
  }
}

export default Footer;
