import React, { Component } from "react";
import { Carousel} from "react-bootstrap";
import Typewriter from "typewriter-effect";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
class Banner extends Component {
  state = {
    isOpen: false,
    display: false,
  };

  openModal = () => {
    this.setState({ isOpen: true });
  };
  componentDidMount() {
    this.setState({ display: true });
  }
  render() {
    return (
      <React.Fragment>
        <Carousel
          fade
          interval={5000}
          pause={false}
          controls={false}
          indicators={false}
        >
          <Carousel.Item>
            <div className="bg-img">
              <img src={require("../../images/tecnologia.jpg")} alt="logo" />
            </div>
            <Carousel.Caption>
              <Typewriter
                onInit={(typewriter) => {
                  typewriter.changeDelay(50).start();
                  typewriter
                    .typeString("TE ASESORAMOS EN LA ")
                    .typeString(
                      '<strong><span style="color: #ff6600;">TRANSFORMACION DIGITAL</span></strong>'
                    );
                  typewriter.typeString(" DE TU EMPRESA.");
                }}
              />
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div className="bg-img">
              <img src={require("../../images/tecnologia1.jpg")} alt="logo" />
            </div>
            <Carousel.Caption>
              <Typewriter
                onInit={(typewriter) => {
                  typewriter.pauseFor(5500).start();
                  typewriter.changeDelay(50);
                  typewriter
                    .typeString("ADMINISTRA ")
                    .typeString(
                      '<strong><span style="color: #ff6600;">EFICIENTEMENTE</span> TU NEGOCIO.</strong>'
                    );
                }}
              />
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div className="bg-img">
              <img src={require("../../images/tecnologia3.jpg")} alt="logo" />
            </div>
            <Carousel.Caption>
              <Typewriter
                onInit={(typewriter) => {
                  typewriter.pauseFor(10500).start();
                  typewriter.changeDelay(50);
                  typewriter
                    .typeString("IMPLEMENTA ")
                    .typeString(
                      '<strong><span style="color: #ff6600;">SOLUCIONES TECNOLOGICAS</span> INNOVADORAS.</strong>'
                    );
                }}
              />
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        <section className="separator-area"></section>
      </React.Fragment>
    );
  }
}

export default Banner;
