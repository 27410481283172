import React, { Component } from "react";
import ModalVideo from "react-modal-video";
import ReCAPTCHA from "react-google-recaptcha";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import config from "../../config";

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      display: false,
      isLoading: false,
      isError: false,
      isSuccess: false,
      name: "",
      last_name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      btnValue: "",
      key: "",
      isErrorName: false,
      isErrorLastname: false,
      isErrorPhone: false,
      isErrorEmail: false,
      isErrorSubject: false,
      isErrorMessage: false,
      isErrorCaptcha: false,
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  validate() {
    var errors = {};
    const textValidate = /^[a-zA-ZÀ-ÿ\s]{3,40}$/; //Solo Letras y espacios, pueden llevar acentos.
    const numberValidate = /^\d{9}$/; // Se valida que solo tenga 9 numeros.
    const emailValidate = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/; // Se valida el correo.

    errors.isErrorName = !textValidate.test(this.state.name);
    errors.isErrorEmail = !emailValidate.test(this.state.email);
    errors.isErrorPhone = !numberValidate.test(this.state.phone);
    errors.isErrorSubject = this.state.message.length < 3;
    errors.isErrorMessage = this.state.message.length < 3;
    errors.isErrorCaptcha = process.env.REACT_APP_ENV === "production" && this.state.key.length < 64;
    errors.hasError =
      errors.isErrorName ||
      errors.isErrorEmail ||
      errors.isErrorPhone ||
      errors.isErrorMessage ||
      errors.isErrorSubject ||
      errors.isErrorCaptcha;
    return errors;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const errors = this.validate();
    if (errors.hasError) {
      errors.hasError = undefined;
      this.setState({ ...errors });
      return;
    }
    this.setState({ ...errors, isLoading: true });
    try {
      if (this.state.btnValue === "submitwhatsapp") {
        this.openWhatsAppWindow();
      }

      if (this.state.btnValue === "submitemail") {
        this.contact({
          name: this.state.name,
          last_name: this.state.last_name,
          email: this.state.email,
          phone: this.state.phone,
          subject: this.state.subject,
          message: this.state.message,
          key: this.state.key,
        });
      }
    } catch (e) {
      console.error(e);
      this.setState({ isLoading: false, isError: true, isSuccess: false });
    }
  };

  contact(data) {
    return fetch(config.url.api + "/contact", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        if (response.code === 0) {
          this.setState({
            isLoading: false,
            isError: false,
            isSuccess: true,
            name: "",
            last_name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
            key: "",
          });
        } else {
          this.setState({ isLoading: false, isError: true, isSuccess: false });
        }
      });
  }

  openWhatsAppWindow = () => {
    let url =
      "https://api.whatsapp.com/send?phone=56940870001&text=Hola, deseo obtener más información, mis datos son los siguientes: " +
      "%0A%0ANombre: " +
      this.state.name +
      "%0A%0ACorreo: " +
      this.state.email +
      "%0A%0ATeléfono: " +
      this.state.phone +
      "%0A%0AAsunto: " +
      this.state.subject +
      "%0A%0AMensaje: " +
      this.state.message +
      "%0A";
    window.open(url, "_blank");
    this.setState({
      isLoading: false,
      isError: false,
      isSuccess: true,
      name: "",
      last_name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      key: "",
    });
  };

  handleClose = () => {
    this.setState({ isSuccess: false, isError: false });
  };
  handleChangeRC = (value) => {
    this.setState({ key: value, isEmailError: false, isKeyError: false });
  };

  openModal = () => {
    this.setState({ isOpen: true });
  };

  componentDidMount() {
    this.setState({ display: true });
  }

  render() {
    return (
      <React.Fragment>
        <div
          id="home"
          className="slider_area about-area ptb-100"
          data-jarallax='{"speed": 0.2}'
        >
          <div className="container">
            <div className="row default-section-title">
              <div className="col-lg-6 col-md-6 pl-md-5 pl-lg-5">
                <div className="container">
                  <div className="row justify-content-center">
                    <img
                      src={require("../../images/white-logo.png")}
                      alt="logo"
                      style={{ height: 300 }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4pl-md-5 pl-lg-5">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="contact-info-box">
                      <div className="row justify-content-center">
                        <h6>Escríbenos para más información</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <form id="contactForm" onSubmit={this.handleSubmit}>
                  <div className="row justify-content-md-center">
                    <div className="col-lg-10 col-md-10">
                      <div className="form-group">
                        <input
                          id="name"
                          placeholder="Ingrese su nombre *"
                          value={this.state.name}
                          type="text"
                          className={
                            "form-control" +
                            (this.state.isErrorName ? " is-invalid" : "")
                          }
                          required={true}
                          data-error="Ingresa tu nombre"
                          onChange={this.handleChange}
                        />
                        <div className="help-block with-errors">
                          {this.state.isErrorName && (
                            <div className="invalid-feedback">
                              Debes ingresar un nombre válido
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-md-center">
                    <div className="col-lg-10 col-md-10">
                      <div className="form-group">
                        <input
                          id="email"
                          placeholder="Ingrese su correo *"
                          value={this.state.email}
                          type="email"
                          className={
                            "form-control" +
                            (this.state.isErrorEmail ? " is-invalid" : "")
                          }
                          required={true}
                          data-error="Ingresa tu email"
                          onChange={this.handleChange}
                        />
                        <div className="help-block with-errors">
                          {this.state.isErrorEmail && (
                            <div className="invalid-feedback">
                              Debes ingresar un email válido
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-md-center">
                    <div className="col-lg-10 col-md-10">
                      <div className="form-group">
                        <input
                          id="phone"
                          placeholder="Ingrese su télefono *"
                          value={this.state.phone}
                          type="text"
                          className={
                            "form-control" +
                            (this.state.isErrorPhone ? " is-invalid" : "")
                          }
                          required={true}
                          data-error="Ingresa el télefono"
                          onChange={this.handleChange}
                        />
                        <div className="help-block with-errors">
                          {this.state.isErrorPhone && (
                            <div className="invalid-feedback">
                              Debes ingresar un télefono con 9 digitos
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-md-center">
                    <div className="col-lg-10 col-md-10">
                      <div className="form-group">
                        <input
                          id="subject"
                          placeholder="Ingrese un asunto *"
                          value={this.state.subject}
                          type="text"
                          className={
                            "form-control" +
                            (this.state.isErrorSubject ? " is-invalid" : "")
                          }
                          required={true}
                          data-error="Ingresa un asunto"
                          onChange={this.handleChange}
                        />
                        <div className="help-block with-errors">
                          {this.state.isErrorSubject && (
                            <div className="invalid-feedback">
                              Debes ingresar un asunto
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-md-center">
                    <div className="col-lg-10 col-md-10">
                      <div className="form-group">
                        <textarea
                          id="message"
                          placeholder="Ingrese el mensaje *"
                          value={this.state.message}
                          className={
                            "form-control" +
                            (this.state.isErrorMessage ? " is-invalid" : "")
                          }
                          cols="30"
                          rows="4"
                          required={true}
                          data-error="Escribe el mensaje"
                          onChange={this.handleChange}
                        ></textarea>
                        <div className="help-block with-errors">
                          {this.state.isErrorMessage && (
                            <div className="invalid-feedback">
                              Debes ingresar mensaje válido
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-md-center">
                    <ReCAPTCHA
                      sitekey={config.ReCAPTCHA}
                      onChange={this.handleChangeRC}
                      className="recaptcha"
                    />
                    <div className="help-block with-errors">
                      {this.state.isErrorCaptcha && (
                        <div className="invalid-feedback">
                          Debes completar captcha
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="text-center">
                    {(this.state.isError || this.state.isSuccess) && this.state.btnValue === "submitwhatsapp" && (
                      <div className={"alert alert-" + (this.state.isError ? "danger" : "success") + " col-lg-12 col-md-12"} role="alert">
                        {this.state.isError ? (
                          <div>
                            <i class="fas fa-times-circle"></i> No se ha
                            podido redirigir a la página, por favor intenta
                            nuevamente.
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-label="Close"
                              onClick={() => this.handleClose()}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        ) : (
                          <div>
                            <i class="fas fa-check-circle"></i> Redireccionamiento exitoso; te contactaremos
                            prontamente.
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-label="Close"
                              onClick={() => this.handleClose()}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                    {(this.state.isError || this.state.isSuccess) &&
                      this.state.btnValue === "submitemail" && (
                        <div
                          className={
                            "alert alert-" +
                            (this.state.isError ? "danger" : "success") +
                            " col-lg-12 col-md-12"
                          }
                          role="alert"
                        >
                          {this.state.isError ? (
                            <div>
                              <i className="fas fa-times-circle"></i> Error al enviar el
                              mensaje, por favor intenta nuevamente.
                              <button
                                type="button"
                                className="close"
                                data-dismiss="alert"
                                aria-label="Close"
                                onClick={() => this.handleClose()}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                          ) : (
                            <div>
                              <i className="fas fa-check-circle"></i> Envio exitoso; te contactaremos prontamente.
                              <button
                                type="button"
                                className="close"
                                data-dismiss="alert"
                                aria-label="Close"
                                onClick={() => this.handleClose()}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    <div className="row justify-content-md-center">
                      <div className="contact-submit">
                        <button
                          type="submit"
                          value="submitemail"
                          className="btn btn-primary"
                          onClick={(e) =>
                            this.setState({ btnValue: e.target.value })
                          }
                          disabled={this.state.isLoading}
                        >
                          {this.state.isLoading
                            ? "Enviando..."
                            : "Enviar Mensaje"}
                        </button>
                      </div>
                      {/* 
                      <div className="contact-submit">
                        <button
                          type="submit"
                          value="submitwhatsapp"
                          className="btn btn-primary"
                          onClick={(e) =>
                            this.setState({ btnValue: e.target.value })
                          }
                          disabled={this.state.isLoading}
                        >
                          {this.state.isLoading
                            ? "Enviando..."
                            : "Enviar Whatsapp"}
                        </button>
                      </div> */}
                      <div
                        id="msgSubmit"
                        className="h3 text-center hidden"
                      ></div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId=""
          onClose={() => this.setState({ isOpen: false })}
        />
      </React.Fragment>
    );
  }
}

export default Banner;
