import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';


class Navbar extends Component {

    state = {
        collapsed: true,
    };

    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    componentDidMount() {
        this._isMounted = true;
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);

        this.menuActiveClass()
    }

    menuActiveClass = () => {
        let mainNavLinks = document.querySelectorAll("nav ul li a");
        window.addEventListener("scroll", () => {
            let fromTop = window.scrollY;
            mainNavLinks.forEach(link => {
                if (link.hash){
                    let section = document.querySelector(link.hash);

                    if (section &&
                        section.offsetTop <= fromTop &&
                        section.offsetTop + section.offsetHeight > fromTop
                    ) {
                        link.classList.add("active");
                    } else {
                        link.classList.remove("active");
                    }
                }
            });
        });
    }

    render() {
        const { collapsed } = this.state;
        const classOne = collapsed ? 'collapse navbar-collapse' : 'navbar-collapse collapse show';
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
        return (
            <React.Fragment>
            <nav id="navbar" className="navbar navbar-expand-lg navbar-style-one navbar-light bg-light">
                <div className="container">
                    <AnchorLink
                        onClick={this.toggleNavbar}
                        offset={() => 100}
                        className="navbar-brand"
                        href="#home"
                    >
                      <img src={require('../../images/white-logo-nav.png')} alt="logo" />
                      <img src={require('../../images/black-logo.png')} alt="logo" />
                      
                    </AnchorLink>
                    <button
                        onClick={this.toggleNavbar}
                        className={classTwo}
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className={classOne} id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <AnchorLink
                                    onClick={this.toggleNavbar}
                                    offset={() => 100}
                                    className="nav-link active"
                                    href="#home"
                                >
                                    Inicio
                                </AnchorLink>
                            </li>
                            <li className="nav-item">
                                <AnchorLink
                                    onClick={this.toggleNavbar}
                                    offset={() => 0}
                                    className="nav-link"
                                    href="#about"
                                >
                                    Nosotros
                                </AnchorLink>
                            </li>
                            <li className="nav-item">
                                <AnchorLink
                                    onClick={this.toggleNavbar}
                                    offset={() => 0}
                                    className="nav-link"
                                    href="#metodologia"
                                >
                                    Metodología
                                </AnchorLink>
                            </li>
                            <li className="nav-item">
                                <AnchorLink
                                    onClick={this.toggleNavbar}
                                    offset={() => 0}
                                    className="nav-link"
                                    href="#consultorias"
                                >
                                    Consultoría
                                </AnchorLink>
                            </li>
                            {/*
                            <li className="nav-item">
                                <AnchorLink
                                    onClick={this.toggleNavbar}
                                    offset={() => 0}
                                    className="nav-link"
                                    href="#testimonials"
                                >
                                    Testimonios
                                </AnchorLink>
                            </li>
                            <li className="nav-item">
                                <AnchorLink
                                    onClick={this.toggleNavbar}
                                    offset={() => -10}
                                    className="nav-link"
                                    href="#team"
                                >
                                    Nuestro Equipo
                                </AnchorLink>
                            </li>
                            */}
                            <ul className='others-option'>
                            <li>
                                <AnchorLink
                                    onClick={this.toggleNavbar}
                                    offset={() => -10}
                                    className="nav-btn btn-primary"
                                    href="#home"
                                >
                                    Contacto
                                </AnchorLink>
                            </li>
                            </ul>
                            
                        </ul>

                    </div>
                </div>
            </nav>
            </React.Fragment>
        );
    }
}

export default Navbar;
