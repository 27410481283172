const local = {
  ga_analytics: '',
  url: {
    api: "https://api.labs.isawesome.cl"
  },
  ReCAPTCHA: '6LdAKCwhAAAAAJKDhzBeu_MZfqv3GFOuGxSQqFbe'
};

const dev = {
  ga_analytics: '',
  url: {
    api: "https://api.labs.isawesome.cl"
  },
  ReCAPTCHA: '6LdAKCwhAAAAAJKDhzBeu_MZfqv3GFOuGxSQqFbe'
};

const prod = {
  ga_analytics: '',
  url: {
    api: "https://api.isawesome.cl"
  },
  ReCAPTCHA: '6LdAKCwhAAAAAJKDhzBeu_MZfqv3GFOuGxSQqFbe'
};

const config = process.env.REACT_APP_ENV === 'production'
  ? prod
  : process.env.REACT_APP_ENV === 'development'
  ? dev
  : local;

console.log("REACT_APP_ENV:" + process.env.REACT_APP_ENV);

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
