import React, { Component } from "react";
import { Carousel} from "react-bootstrap";
import Typewriter from "typewriter-effect";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
class Banner extends Component {
  state = {
    isOpen: false,
    display: false,
  };

  openModal = () => {
    this.setState({ isOpen: true });
  };
  componentDidMount() {
    this.setState({ display: true });
  }
  render() {
    return (
      <React.Fragment>
        <Carousel
          fade
          interval={5000}
          pause={false}
          controls={false}
          indicators={false}
        >
          <Carousel.Item>
            <div className="bg-img">
              <img src={require("../../images/financiera1.jpg")} alt="logo" />
            </div>
            <Carousel.Caption>
              <Typewriter
                onInit={(typewriter) => {
                  typewriter.changeDelay(50).start();
                  typewriter
                    .typeString("PERMITENOS ANALIZAR LA ")
                    .typeString(
                      '<strong><span style="color: #ff6600;">SITUACION FINANCIERA</span></strong>'
                    );
                  typewriter.typeString(" DE TU EMPRESA.");
                }}
              />
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div className="bg-img">
              <img src={require("../../images/financiera2.jpg")} alt="logo" />
            </div>
            <Carousel.Caption>
              <Typewriter
                onInit={(typewriter) => {
                  typewriter.pauseFor(5500).start();
                  typewriter.changeDelay(50);
                  typewriter
                    .typeString("DISMINUYE ")
                    .typeString(
                      '<strong><span style="color: #ff6600;">GASTOS</span>, <span style="color: #ff6600;">COSTOS</span></strong>'
                    );
                  typewriter
                    .typeString(" Y ADMINISTRA TUS ")
                    .typeString(
                      '<strong><span style="color: #ff6600;">RIESGOS</span>.</strong>'
                    );
                }}
              />
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div className="bg-img">
              <img src={require("../../images/financiera4.jpg")} alt="logo" />
            </div>
            <Carousel.Caption>
              <Typewriter
                onInit={(typewriter) => {
                  typewriter.pauseFor(10500).start();
                  typewriter.changeDelay(50);
                  typewriter
                    .typeString("OBTEN EL ")
                    .typeString(
                      '<strong><span style="color: #ff6600;">FINANCIAMIENTO BANCARIO</span></strong>'
                    );
                  typewriter.typeString(" QUE NECESITAS.");
                }}
              />
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        <section className="separator-area"></section>
      </React.Fragment>
    );
  }
}

export default Banner;
