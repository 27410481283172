import React, { Component } from 'react';

class About extends Component {
    render() {
        return (
            <section id="about" className="about-area ptb-100">
                <div className="container"> 
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-md-12">
                            <div className="default-section-title">
                                <h2>Nosotros</h2>
                                <div className="bar"></div>
                            </div>
                            <div className="about-image">
                                <img src={require('../../images/nosotros.jpg')} alt="about" />
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="about-content">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="single-inner-content">
                                            <div className="title">
                                                <div className="icon">
                                                    <i className="fas fa-lock"></i>
                                                </div>

                                                <h3>EXPERIENCIA</h3>
                                            </div>

                                            <p>Somos un equipo de profesionales con más de 20 años de experiencia trabajando en bancos y empresas, principalmente en áreas comerciales, financieras y de riesgo.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="single-inner-content">
                                            <div className="title">
                                                <div className="icon">
                                                    <i className="fas fa-lightbulb"></i>
                                                </div>

                                                <h3>INNOVACION</h3>
                                            </div>

                                            <p>Ponemos a tú disposición tecnologías que impactarán positivamente la gestión, productividad, eficiencia y control de riesgos, dentro de tú empresa.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="single-inner-content">
                                            <div className="title">
                                                <div className="icon">
                                                    <i className="fas fa-wifi"></i>
                                                </div>

                                                <h3>DIGITAL</h3>
                                            </div>

                                            <p>Nos desarrollamos en un entorno digital, trabajamos híbridamente y buscamos con obsesión automatizar todos nuestros servicios de consultoría.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="single-inner-content">
                                            <div className="title">
                                                <div className="icon">
                                                    <i className="fas fa-sync"></i>
                                                </div>

                                                <h3>TRANSFORMACION</h3>
                                            </div>

                                            <p>Hemos sido agentes de cambio en todas las empresas que hemos trabajado; ahora queremos acompañarte en el cambio que transformará tu empresa.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default About;
