import React from "react";
import { useCookies } from "react-cookie";
import { Steps, useSteps } from "react-step-builder";
import { Modal, Button } from "react-bootstrap";

function ModalCookies() {
  const { prev, next} = useSteps();

  const [cookies, setCookie] = useCookies(["allowcookies"]);

  function handleCookie() {
    setCookie("allowcookies", true, {
      path: "/",
      expires: new Date(Date.now() + 100 * 24 * 60 * 60 * 1000),
    });
  }

  function closeModal() {
    handleCookie();
  }

  return (
    <Modal
      className="Modal-clase"
      show={cookies.allowcookies ? false : true}
      onHide={closeModal}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
       {/* <img
          src={require("../../images/white-logo.png")}
          alt="logo"
          style={{ height: 230 }}
  />*/}
        <Steps>
          <div className="step">
            <h1>¿Que?</h1>
            <h4>
              Somos una empresa digital que presta servicios de asesoría
              estratégica, financiera y tecnológica a pequeñas y medianas
              empresas de Chile.
            </h4>
            <div className="row">
              <Button variant="primary" onClick={next}>
                Siguiente
              </Button>
            </div>
          </div>
          <div className="step">
            <h1>¿Para Quién?</h1>
            <h4>
              Si eres dueño, accionista o gerente general de una empresa,
              contáctanos, agenda una videoconferencia o envíanos un mensaje
              para resolver tus inquietudes.
            </h4>
            <div className="row">
              <Button variant="primary" onClick={prev}>
                Anterior
              </Button>
              <Button variant="primary" onClick={next}>
                Siguiente
              </Button>
            </div>
          </div>
          <div className="step">
            <h1>¿Cómo?</h1>
            <h4>
              Solo necesitamos analizar la información de tu empresa y te
              haremos llegar una propuesta.
            </h4>
            <div className="row">
              <Button variant="primary" onClick={prev}>
                Anterior
              </Button>
              <Button variant="primary" onClick={next}>
                Siguiente
              </Button>
            </div>
          </div>
          <div className="step">
            <h1>¿Para Qué?</h1>
            <h4>
              Buscamos aumentar tus ingresos, reducir costos, controlar riesgos
              y/o mejorar la experiencia digital en tu empresa.
            </h4>
            <div className="row">
              <Button variant="primary" onClick={prev}>
                Anterior
              </Button>
              <Button variant="primary" onClick={next}>
                Siguiente
              </Button>
            </div>
          </div>
          <div className="step">
            <h1>¿Porque?</h1>
            <h4>
              Deseamos superar tus expectativas, cumplir nuestra promesa de
              servicio y generar valor en tu empresa.
            </h4>
            <div className="row">
              <Button variant="primary" onClick={prev}>
                Anterior
              </Button>
              <Button variant="primary" onClick={closeModal}>
                Ir a www.isawesome.cl
              </Button>
            </div>
          </div>
        </Steps>
      </Modal.Body>
    </Modal>
  );
}

export default ModalCookies;
