import React from "react";
import { Route, Switch} from "react-router-dom";

import Home from './pages/Home';
import Services from './components/Home/Services';
import Process from './pages/Strategic';
import Financial from './pages/Financial';
import Technology from './pages/Technology';
import NotFound from './pages/NotFound';

export default ({ childProps }) =>

<Switch>
  <Route path="/" exact component={Home} props={childProps} />
  <Route path="/consultancy" exact component={Services} props={childProps} />
  <Route path="/process" exact component={Process} props={childProps} />
  <Route path="/financial" exact component={Financial} props={childProps} />
  <Route path="/technology" exact component={Technology} props={childProps} />
  <Route component={NotFound} />
</Switch>;




 
