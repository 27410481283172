import React, { Component } from 'react';
import Navbar from '../components/Layouts/NavbarSix';
import Footer from '../components/Layouts/FooterTwo';
import Banner from '../components/Services/BannerStrategic';
import Company from '../components/Services/Strategic';


class Process extends Component {
    componentDidMount() {
      window.scrollTo(0, 0);
    }
    render() {
        return (
            <React.Fragment>
                <Navbar />
                <Banner />
                <Company />
                <Footer />
            </React.Fragment>
        );
    }
}

export default Process;
